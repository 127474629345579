<template>
	<v-container>
		<v-row>
			<v-col cols="8">
				<v-text-field label="Номер" v-model="searchStationNo" outlined dense hide-details class="mb-2" />
				<v-text-field label="Адрес" v-model="searchStationAddress" outlined dense hide-details class="mb-2" />
				<filter-value label="Шаблон" v-model="searchTemplate" outlined dense hide-details class="mb-2"
					:rule="{field: 'n_station.n_utility_session_template_id'}"
					:imodel="model"
				/>
				<filter-value :label="model.fields['nsd.mesechen'].label" v-model="searchMesechen" outlined dense hide-details
					:rule="{field: 'nsd.mesechen'}"
					:imodel="model"
					input-type="select"
				/>
			</v-col>
			<v-col cols="4">
				<v-checkbox label="В същия район" v-model="searchSameArea" dense hide-details class="mt-0" />
				<v-checkbox label="Без район" v-model="searchNoArea" dense hide-details class="mt-0" />
				<v-checkbox label="От други райони" v-model="searchOtherAreas" dense hide-details class="mt-0" />
			</v-col>
		</v-row>
		<v-row v-if="radioCount">
			<v-col cols="12">
				<v-alert type="info" dense>
					Радио уреди в района: <strong>{{radioCount}}</strong>,
					радио водомери: <strong>{{radioVodomerCount}}</strong>
				</v-alert>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<list
					:imodel="model"
					item-key="n_station.id"
					:headers="headers.join(',')"
					:params="{utility:utilityCode,area:areaId}"
					:filter="filter"
					hide-edit hide-delete hide-export
					ref="list"
				>
					<template #select-actions="{selected}">
						<v-btn small icon dark class="ml-2 green" @click="add(selected.map(s=>s['n_station.id']))">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
						<v-btn small icon dark class="ml-2 orange" @click="remove(selected.map(s=>s['n_station.id']))">
							<v-icon>mdi-minus</v-icon>
						</v-btn>
					</template>
					<template #top-actions>
						<v-btn small dark class="ml-2 my-2 green" @click="addAll">
							<v-icon left>mdi-plus</v-icon>
							Добави всички
						</v-btn>
						<v-btn small dark class="ml-2 my-2 orange" @click="removeAll">
							<v-icon left>mdi-minus</v-icon>
							Премахни всички
						</v-btn>
					</template>
				</list>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import List from "@/ittijs/List";
import StationAreaList from "../station_areas/StationAreasListModel";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import IModelMixin from "@/ittijs/IModelMixin";
import {itemsForSelect} from "@/ittijs/utils";
import FilterValue from "@/ittijs/Filter/Value";
import {FilterOps} from "@/ittijs/ITTIModel";

const model = ITTIModelProvider.getModel(StationAreaList);

export default {
	mixins: [IModelMixin],
	components: {FilterValue, List},
	props: {
		'areaId': {},
		'utilityCode': {},
	},
	data(){
		return {
			model,
			searchStationNo: '',
			searchStationAddress: '',
			searchTemplate: '',
			searchMesechen: null,
			searchSameArea: true,
			searchNoArea: true,
			searchOtherAreas: false,
			radioCount: null,
			radioVodomerCount: null,
			itemsForSelect,
		}
	},
	async mounted() {
		await this.loadRadioCount();
	},
	computed: {
		headers(){
			const ts = 'n_station.';
			return [
				ts+'utility_code',
				ts+'n_utility_session_template_id',
				ts+'station_N',
				ts+'address',
				'nsd.mesechen',
				'n_areas.name',
				'count_radio',
				'count_radio_vodomer',
				'count_nonradio',
			];
		},
		searchAllSelected(){
			return this.searchSameArea && this.searchNoArea && this.searchOtherAreas;
		},
		filter(){
			const filter = {
				glue: 'AND',
				rules: [
					{
						field: 'n_station.utility_code',
						value: this.utilityCode,
						op: 'eq',
					}
				],
				groups: {},
			};

			if (this.searchStationNo) {
				filter.rules.push({
					field: 'n_station.station_N',
					op: 'eq',
					value: this.searchStationNo,
				});
			}

			if (this.searchStationAddress) {
				filter.rules.push({
					field: 'n_station.address',
					op: 'cn',
					value: this.searchStationAddress,
				});
			}

			if (this.searchTemplate) {
				filter.rules.push({
					field: 'n_station.n_utility_session_template_id',
					op: 'eq',
					value: this.searchTemplate,
				});
			}

			if (this.searchMesechen) {
				filter.rules.push({
					field: 'nsd.mesechen',
					op: 'eq',
					value: this.searchMesechen,
				});
			}

			if (!this.searchAllSelected) {
				const group = {
					glue: 'OR',
					rules: [],
				};
				if (this.searchSameArea) {
					group.rules.push({
						field: 'n_areas_rel.area_id',
						op: FilterOps.EQUALS,
						value: this.areaId,
					});
				}
				if (this.searchNoArea) {
					group.rules.push({
						field: 'n_areas_rel.area_id',
						op: FilterOps.NULL,
					});
				}
				if (this.searchOtherAreas) {
					group.rules.push({
						field: 'n_areas_rel.area_id',
						op: FilterOps.NOT_IN,
						value: [null, this.areaId],
					});
				}
				if (!group.rules.length) group.rules.push({
					op: FilterOps.FALSE, // never match
				});
				filter.groups.g1 = group;
			}

			return filter;
		},
	},
	methods: {
		async add(selected){
			await model.add(this.areaId, selected);
			await this.loadRadioCount();
			this.$refs.list.refresh();
		},
		async remove(selected){
			await model.remove(this.areaId, selected);
			await this.loadRadioCount();
			this.$refs.list.refresh();
		},
		async addAll(){
			await model.addAll(this.areaId, this.filter);
			await this.loadRadioCount();
			this.$refs.list.refresh();
		},
		async removeAll(){
			await model.removeAll(this.areaId, this.filter);
			await this.loadRadioCount();
			this.$refs.list.refresh();
		},
		async loadRadioCount(){
			const res = await model.getRadioCount(this.areaId, this.utilityCode);
			this.radioCount = res['total'];
			this.radioVodomerCount = res['vodomeri'];
		},
	},
}
</script>