import ITTIModel from '../../ittijs/ITTIModel';
export default class extends ITTIModel {
	constructor() {
		super('StationAreaList');
	}
	add(areaId, stationIds){
		return this.fetch('add', {areaId, stationIds}, {});
	}
	remove(areaId, stationIds){
		return this.fetch('remove', {areaId, stationIds}, {});
	}
	addAll(areaId, filter){
		return this.fetch('addAll', {areaId, filter}, {});
	}
	removeAll(areaId, filter){
		return this.fetch('removeAll', {areaId, filter}, {});
	}
	getRadioCount(areaId, utility){
		return this.fetch('getRadioCount', {areaId, utility});
	}
}