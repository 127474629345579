<!--
Отделен интерфейс за редакция на райони, за хора, на които не искаме да даваме
права за редакция на дружество
-->
<template>
	<please-select-utility alert-class="ma-3">
		<v-toolbar flat dense>
			<v-toolbar-title>Райони - {{ title }}</v-toolbar-title>
			<v-spacer/>
			<v-btn color="primary" @click="onAddArea">
				<v-icon left>mdi-plus</v-icon>
				Нов район
			</v-btn>
		</v-toolbar>
		<v-divider />
		<List
			ref="areas"
			v-if="utility && group"
			headers="name,stationCount"
			hidden-cols="utility_code,area_group"
			:imodel="areaModel"
			:params="{utility_code:utility,area_group:group}"
			disable-pagination
			hide-select
			@edit="onEditArea"
		>
			<template #right-actions="{item}">
				<my-dialog @close="reloadAreas" content-class="dialog-top" max-width="1200" :title="`Станции в район &quot;${item.name}&quot;`">
					<template #activator="{attrs,on}">
						<v-btn small v-bind="attrs" v-on="on">станции</v-btn>
					</template>
					<template #default="{state}">
						<edit-area-stations v-if="state" :utility-code="item.utility_code" :area-id="item.id" />
					</template>
				</my-dialog>
			</template>
		</List>
		<v-dialog v-model="editArea" max-width="600">
			<v-card>
				<edit-column
					v-if="editArea"
					max-content-height="calc(90vh - 120px)"
					title="Район"
					:id="areaId"
					:imodel="areaModel"
					:constrains="{utility_code:utility,area_group:group}"
					hide-constrained
					@close="editArea = false; reloadAreas();"
					@saveclose="editArea = false; reloadAreas();"
				/>
			</v-card>
		</v-dialog>
	</please-select-utility>
</template>
<script>
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import EditAreaStations from "@/views/utility/EditAreaStations";
import List from "@/ittijs/List";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import AreaModel from "@/views/station_areas/Model";
import MyDialog from "@/ittijs/components/MyDialog";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import EditColumn from "@/ittijs/EditColumn";

const areaModel = ITTIModelProvider.getModel(AreaModel);

export default {
	components: {EditColumn, MyDialog, List, EditAreaStations, PleaseSelectUtility},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
			group: 'selectedGroup',
		}),
	],
	props: [
		'group',
		'utility',
	],
	data(){
		return {
			areaModel,
			editArea: false,
			areaId: null,
			selectedGroup: null,
		}
	},
	computed: {
		title(){
			const map = new Map([
				['monthly', 'Мес. отчет'],
				['yearly', 'Год. отчет'],
				['montaji', 'Монтажи'],
			]);
			return map.get(this.group);
		},
	},
	methods: {
		onEditArea($event){
			this.areaId = $event.id;
			this.editArea = true;
		},
		onAddArea(){
			this.areaId = 'new';
			this.editArea = true;
		},
		reloadAreas(){
			this.$refs.areas.getDataFromApi();
		},
	},
}
</script>